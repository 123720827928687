import React, { useContext, useEffect, useState } from 'react'
import { Divider, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { UserContext } from '../../../../context/UserProvider';
import { db } from '../../../../config/firebase';
import { backGroundList } from '../../../../constants/globalConst';
import UpdatingButton from './ConfigComponents/UpdatingButton';
import DialogConfirm from './ConfigComponents/DialogConfirm';
import { OffsetsLoraNodes } from './ConfigNodeComponents/OffsetsLoraNodes';


const useStyles = makeStyles((theme) => ({
    formField: {
      margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
      },
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    input: {
      // Ajusta el tamaño del input y otros estilos aquí
      width: '100%', // Hace que el input ocupe todo el ancho del contenedor
      padding: theme.spacing(1), // Usa el sistema de espaciado de Material-UI para el padding
      [theme.breakpoints.down('sm')]: {
        // Ajustes para pantallas pequeñas (dispositivos móviles)
        padding: theme.spacing(0.5),
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
      minHeight: 50,
    },
  }));

export const ConfigLoraNode = (propiedades) => {
    const data = propiedades.data;
    const ide = data.ide;
    const arrayDeCadenas = ide.split("@");
    const nodeNumber = arrayDeCadenas[2];
    const macId = arrayDeCadenas[0]; 
    const classes = useStyles();

    const { usuario } = useContext(UserContext);
    const [error, setError] = useState(null);
    const [openWindow, setOpenWindow] = useState(false);
    const [newName, setNewName] = useState('');
    const [dataConfig, setDataConfig] = useState({
        nodeName: "-",
        nodeId: "-",
        fecha: "-",
        offset_values: ["-", "-", "-", "-", "-", "-", "-"],
        offset_signs: ["0", "0", "0", "0", "0", "0", "0"],
    });

    const [sensors, setSensors] = useState([
      { signValue: '0', offsetValue: '', changed: false, sensorId: 1 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 2 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 3 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 4 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 5 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 6 },
      { signValue: '0', offsetValue: '', changed: false, sensorId: 7 },
      // Agrega más sensores según sea necesario
    ]);
    const [sensorsChanged, setSensorsChanged] = useState();

    // Función para manejar el cambio de signo
    const handleSignChange = (sensorId) => (event) => {
      const newSignValue = event.target.value;
      //console.log(sensorId);
      setSensors(prevSensors => ({
        ...prevSensors,
        [sensorId]: {
          ...prevSensors[sensorId],
          signValue: newSignValue,
          changed: true,
        },
      }));
    };

    const handleNameChange = (e) => {
        setNewName(e.target.value);
    }

    // Función para manejar el cambio de valor
    const handleOffsetChange = (sensorId) => (event) => {
      const newOffsetValue = event.target.value;
      if(newOffsetValue >= 0) {
        setSensors(prevSensors => ({
          ...prevSensors,
          [sensorId]: {
            ...prevSensors[sensorId],
            offsetValue: newOffsetValue,
            changed: true,
          },
        }));
      }
      
    };

    

    useEffect(() => {
        const obtenerDatos = async () => {
          try {
            
            const arrayDeCadenas = ide.split("@");
            const nodeId = arrayDeCadenas[2];
    
            const addr = `${usuario.username}/loraDevices/nodes/${nodeId}/configNode`;
    
            const leerDatosDB = async () => {
              try {
                const docRef = db.collection(addr).doc("renderData");
                const docSnap = await docRef.get();
            
                if (docSnap.exists) {
                  // console.log("Datos del documento:", docSnap.data());
                  const dataItem = docSnap.data();
                  //console.log('Esto es dataItem:', dataItem);
                  setDataConfig(dataItem.item);
                  setNewName(dataItem.item.nodeName);
                  dataItem.item.offset_values.map((value,index) => {
                    setSensors(prevSensors => ({
                      ...prevSensors,
                      [index]: {
                        ...prevSensors[index],
                        offsetValue: value,
                      },
                    }));
                  });

                  dataItem.item.offset_signs.map((value,index) => {
                    setSensors(prevSensors => ({
                      ...prevSensors,
                      [index]: {
                        ...prevSensors[index],
                        signValue: value,
                      },
                    }));
                  });
                  
                  
                } else {
                  // El documento no existe.
                  console.log("No se encontró el documento!");
                }
              } catch (error) {
                console.error("Error al obtener el documento:", error);
              }
            };
            
            leerDatosDB();
          } catch (error) {
            console.log("obtenerDatos: error ", error);
          }
        };
        obtenerDatos();
      }, [ide, usuario.username]);


      const sendingCommand = (sensorNumber, mathSign, integerValue, decimalValue, integerDivision) => {
        const arrayDeCadenas = ide.split("@");
        const nodeId = arrayDeCadenas[2];
        let mqtt = "";
        const len = 6;
        const action = 249;
        
        mqtt =
            len +
            "," +
            action +
            "," +
            nodeId +
            "," +
            sensorNumber +
            "," +
            mathSign +
            "," +
            integerValue +
            "," +
            decimalValue + 
            "," +
            integerDivision;
        sendingOperationCalibration(mqtt);
        //console.log(mqtt);
        // console.log(isOffset);
        //console.log("delay de 0.5 Seg.")
        // Aquí implementamos el delay de 0.5 segundos antes de permitir que la función reciba nuevos datos.
        return new Promise(resolve => setTimeout(resolve, 500));
      }

      const calculateIntegerDivision = ( integerValue ) => {
        const result = Math.floor(integerValue / 256);
        return result
      };

      async function procesarDatosSecuencialmente(datos) {
        for (const item of datos) {
          // Asumiendo que item contiene los parámetros necesarios para sendingCommand
          const value = item.offsetValue;
          const valueSplit = value.split(".");
          const entera = parseInt(valueSplit[0], 10);
          const decimal = valueSplit.length > 1 ? parseInt(valueSplit[1], 10) : 0;
          const integerDivision = calculateIntegerDivision( entera );
          await sendingCommand(item.sensorId, item.signValue, entera, decimal, integerDivision);
          // Aquí se espera a que se complete la promesa de sendingCommand antes de continuar con el siguiente item
        }
      }

      const sendingOperationCalibration = async (e) => {
        //console.log(e)
    
        const item = {
          msMqtt: e,
          mac: macId,
          action: "ConfigOffsetsLora",
          fecha: Date.now(),
          uid: ide,
        };
    
        try {
          const addr = `${usuario.username}/loraDevices/nodes/${nodeNumber}/sendConfigLora`;
          await db.collection(addr).doc("sendConfig").set({ item });
        } catch (error) {
          console.log(error);
        }
      };


      const editar = async (e) => {
        e.preventDefault();
    
        if (!newName.trim()) {
          setError("Ingrese Nombre");
          return;
        }
        setError(null);
        const changedSensors = Object.entries(sensors).filter(([id, sensor]) => sensor.changed);
        setSensorsChanged(changedSensors);
        // console.log("Sensores modificados:", changedSensors);
        
        setOpenWindow(true);
      };


      const agreeDialog = async () => {
        
        //setLoading(true)
        try {
          
          //Array de offsets que cambiaron
          const arraySensorsData = sensorsChanged.map((_, index) => {
            const sensorData = sensorsChanged[index];
            return sensorData[1]
          });

          const newArrayValues = [...dataConfig.offset_values];
          const newArraySigns = [...dataConfig.offset_signs];
          
          for (let index = 0; index < arraySensorsData.length; index++) {
            const sensorNumber = arraySensorsData[index].sensorId;
            const newValue = arraySensorsData[index].offsetValue;
            const newSign = arraySensorsData[index].signValue;
            newArrayValues[sensorNumber - 1] = newValue;
            newArraySigns[sensorNumber - 1] = newSign;            
          }

          const item = { ...dataConfig, fecha: Date.now(), nodeName: newName, offset_values: newArrayValues, offset_signs: newArraySigns ,uid: ide };
          const addr = `${usuario.username}/loraDevices/nodes/${nodeNumber}/configNode`;
    
          await db.collection(addr).doc("renderData").set({ item });
          
          //Envio de datos por MQTT
          for (let index = 0; index < arraySensorsData.length; index++) {
            procesarDatosSecuencialmente([arraySensorsData[index]]);
          }

          //Reset of values that changed
          for (let index = 0; index < arraySensorsData.length; index++) {
            arraySensorsData[index].changed = false;
          } 
          
          setDataConfig({...dataConfig, nodeName: newName});
          setOpenWindow(false);
        } catch (error) {
          console.log(error);
        }
      };

  return (
    <div className="container mt-3">
        <form onSubmit={editar}>
          <div className="row">
            <div className="col-12">
              <ul className="list-group">
                <li
                  className="list-group-item"
                  style={{ background: backGroundList }}
                >
                  <div className="row">
                    <div className=" col-10">
                      <h4 style={{ color: "white" }}>{dataConfig.nodeName}</h4>
                    </div>                   
                  </div>
                </li>
                <li className="list-group-item">

                  <div className="row">
                    <div className="col-6">Nodo:</div>
                    <div className="col-6">{nodeNumber}</div>
                  </div>

                  <div className="row">
                    <div className="col-6">Nombre:</div>
                    <div className="col-6">
                      <input
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control mb-2"
                        onChange={handleNameChange}
                        value={newName}
                      ></input>
                    </div>
                  </div>

                  {dataConfig.fecha && (
                    <div className="row">
                      <div className="col-6">Last Update:</div>
                      <div className="col-6">
                        {/* {moment(comp.fecha).format("llll")} */}
                        {new Date(dataConfig.fecha).toString()}
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: '15px' }}>
                    <Divider className={classes.divider} />
                  </div>

                  <div style={{marginBottom: '15px'}}>
                    <Grid container
                    justifyContent="center"
                    alignItems="center" >
                      <Typography variant="h6" className={classes.title}>
                        Offset
                      </Typography>
                    </Grid>
                  </div>

                  {/* Offset Aqui */}
                  <OffsetsLoraNodes 
                  sensorTitle="Temperatura del ambiente:"
                  sensorId="sensor1"
                  signValue={sensors[0].signValue}
                  offsetValue={sensors[0].offsetValue}
                  handleSignChange={handleSignChange(0)}
                  handleOffsetChange={handleOffsetChange(0)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="Humedad del ambiente:"
                  sensorId="sensor2"
                  signValue={sensors[1].signValue}
                  offsetValue={sensors[1].offsetValue}
                  handleSignChange={handleSignChange(1)}
                  handleOffsetChange={handleOffsetChange(1)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="CO2 del ambiente:"
                  sensorId="sensor3"
                  signValue={sensors[2].signValue}
                  offsetValue={sensors[2].offsetValue}
                  handleSignChange={handleSignChange(2)}
                  handleOffsetChange={handleOffsetChange(2)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="Humedad del Sustrato:"
                  sensorId="sensor4"
                  signValue={sensors[3].signValue}
                  offsetValue={sensors[3].offsetValue}
                  handleSignChange={handleSignChange(3)}
                  handleOffsetChange={handleOffsetChange(3)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="Temperatura del Sustrato:"
                  sensorId="sensor5"
                  signValue={sensors[4].signValue}
                  offsetValue={sensors[4].offsetValue}
                  handleSignChange={handleSignChange(4)}
                  handleOffsetChange={handleOffsetChange(4)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="EC del Sustrato:"
                  sensorId="sensor6"
                  signValue={sensors[5].signValue}
                  offsetValue={sensors[5].offsetValue}
                  handleSignChange={handleSignChange(5)}
                  handleOffsetChange={handleOffsetChange(5)}
                  />
                  <OffsetsLoraNodes 
                  sensorTitle="pH del Sustrato:"
                  sensorId="sensor7"
                  signValue={sensors[6].signValue}
                  offsetValue={sensors[6].offsetValue}
                  handleSignChange={handleSignChange(6)}
                  handleOffsetChange={handleOffsetChange(6)}
                  />

                   
                  {/* <div style={{marginBottom: 'px'}}></div> */}

                </li>
              </ul>
            </div>
          </div>

          <div className="row ">
            {
              //como operador ternario
              error && (
                <div className="col-12 mt-3">
                  <div className="alert alert-danger alert-block">{error}</div>
                </div>
              )
            }
          </div>

          <UpdatingButton type="submit" disabled={false} />
        </form>

        <DialogConfirm
          open={openWindow}
          handleClose={() => setOpenWindow(false)}
          agreeDialog={agreeDialog}
          title={"¿Está seguro que quiere continuar con esta operación?"}
          text={
            "Permite realizar configuraciones de la salidas o entradas del Nodo. Verifique que el Nodo que desea configurar este encendido."
          }
        />
      </div>
  )
}
