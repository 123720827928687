import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TransitionAlertCommand from "./TransitionAlertCommand";
import PopupForm from "./PopupForm";
import { Alert, AlertTitle } from "@material-ui/lab";
import { db } from "../../../../config/firebase";
import { Eco } from "@material-ui/icons";
import { UserContext } from "../../../../context/UserProvider";


/* <div className="col-md-2">
    <button
    type="button"
    onClick={() => {
        setState([...state, []]);
    }}
    className="btn btn-warning btn-block m-2"
    style={{
        opacity: isDragButton ? "0" : "1",
        transition: "opacity 1s",
    }}
    disabled={isDragButton}
    >
    Nueva Col
    </button>

</div> */



const DashBoardHead = ({ isDragButton, onClickSaveDragged, username, mac }) => {

  const {loraMac}=useContext(UserContext);

  const IconDragColumn=()=>{
      if(isDragButton) return <LockIcon />
      return <LockOpenIcon/>
  }

  const [deviceOffline, setDeviceOffline] = useState(false);
  const [deviceOnline, setDeviceOnline] = useState(false);

  useEffect(() => {
    if(mac !== "") { 
      const docPath = `${username}/infoDevices/${mac}/1/stateModule/state`;
      const renderDoc = db.doc(docPath);
      // Verificar si el documento existe
      renderDoc.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          // Si el documento existe, suscribirse a las actualizaciones en tiempo real
          const unsubscribe = renderDoc.onSnapshot((doc) => {
            const data = doc.data();
            const stateDevice = data.isOnline;
            setDeviceOnline(stateDevice);
            setDeviceOffline(!stateDevice);
          });

          // Limpiar la suscripción cuando el componente se desmonte
          return () => {
            unsubscribe();
          };
        } else {
          console.log('No existe la ruta del documento de alertas.');

        }
      }).catch((error) => {
        console.error('Error obteniendo el documento:', error);
      });
    } else if(loraMac !== "") {
      const docPath = `${username}/infoDevices/${loraMac}/1/stateModule/state`;
      const renderDoc = db.doc(docPath);
      // Verificar si el documento existe
      renderDoc.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          // Si el documento existe, suscribirse a las actualizaciones en tiempo real
          const unsubscribe = renderDoc.onSnapshot((doc) => {
            const data = doc.data();
            const stateDevice = data.isOnline;
            setDeviceOnline(stateDevice);
            setDeviceOffline(!stateDevice);
          });

          // Limpiar la suscripción cuando el componente se desmonte
          return () => {
            unsubscribe();
          };
        } else {
          console.log('No existe la ruta del documento de alertas.');

        }
      }).catch((error) => {
        console.error('Error obteniendo el documento:', error);
      });
    }
    
  }, [username, mac, loraMac]);
  

  return (
    <>
    <Grid container justifyContent="flex-end" alignItems="flex-end">
      <Eco color={deviceOffline ? "secondary" : "primary"} fontSize="large"  />
      {deviceOffline ? "Offline" : "Online"}
    </Grid>
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item xs={6}>

        <Button
        variant="contained"
        color="primary"
        startIcon={<IconDragColumn />}
        onClick={onClickSaveDragged}
        />
        
        
      </Grid>

      <Grid item xs={3} />      
      <Grid item xs={3}>
        <PopupForm />
      </Grid>
      
      <Grid item xs={12}>
        <TransitionAlertCommand />
      </Grid>
      
    </Grid>
    {deviceOffline &&
      <Grid container justifyContent="center" alignContent="center">
      <Alert severity="error">
        <AlertTitle>Gapy Master desconectado</AlertTitle>
        Gappier tu módulo principal se encuentra <strong>!Desconectado!</strong> revisalo.
      </Alert>
    </Grid>
    }
    {/* <PopupForm /> */}
    </>
  );
};

export default DashBoardHead;

