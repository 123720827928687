import React from "react";
import { auth, functions, db } from "../config/firebase";
//import {withRouter} from 'react-router-dom'

export const UserContext = React.createContext();

const noUser = {
  uid: null,
  email: null,
  estado: null,
  username: null,
  rol: null,
  photoURL: null,
};

const UserProvider = (props) => {
  const [usuario, setUsuario] = React.useState(noUser);
  //const [vmac,setVmac]=React.useState([])
  const [dataMacCan, setDataMacCan] = React.useState([]);
  const [dataMacCanDash, setDataMacCanDash] = React.useState([]);
  const [numberOfCans, setNumberOfCans] = React.useState([]);
  const [currentMac, setCurrentMac] = React.useState("");
  const [canIdIrrigation, setCanIdIrrigation] = React.useState();
  const [names, setNames] = React.useState([]); 
  const [configIrrigation, setConfigIrrigation] = React.useState([]);
  const [updateNamesFlag, setUpdateNamesFlag] = React.useState(true)
  const [loraMac, setLoraMac] = React.useState("")
  const dataMacDashRef = React.useRef(dataMacCanDash);
  React.useEffect(() => {
    detectarUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (usuario.estado === true) {
      getArrayMac();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  React.useEffect(() => {
    const ChangeFireStore = async () => {
      //const add=usuario.username+'/logs/'+macCan.mac;

      await db
        .collection("usuarios") //.where('act', '==', 'sendConfigModule')
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
              forzarLeecturaCustomClaims();
            }
          });
        });
    };

    if (usuario.username) {
      ChangeFireStore();
    }
  }, [usuario]);


  // Función para eliminar todos los documentos de una subcolección
  const deleteSubcollection = async (collectionPath, docId, subcollectionName) => {
    const subcollectionRef = db.collection(collectionPath).doc(docId).collection(subcollectionName);

    // Obtener todos los documentos de la subcolección
    subcollectionRef.get().then(snapshot => {
      // Crear un batch para eliminar todos los documentos
      const batch = db.batch();

      snapshot.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Commit del batch para eliminar los documentos
      batch.commit().then(() => {
        console.log("Subcolección eliminada con éxito");
      }).catch(error => {
        console.error("Error al eliminar la subcolección: ", error);
      });
    }).catch(error => {
      console.error("Error al obtener documentos de la subcolección: ", error);
    });
  };

  

  const getArrayMac = async () => {
    try {
      const getSubCollections = functions.httpsCallable("getSubCollections");
      const listColl = await getSubCollections({
        docPath: `${usuario.username}/infoDevices`,
      });
      //setVmac(listColl.data.collections)
      const collectionRef = db.collection("deviceUserMapping")
      const querySnapshot = await collectionRef.get();
      const docsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredResults = listColl.data.collections.filter(name => {
        const doc = docsData.find(doc => doc.id === name); // Cambia 'name' por el campo que estás comparando
        return doc && doc.userId === usuario.username; // Cambia 'someField' y 'desiredValue' según tus necesidades
      });
      
      for (let index = 0; index < filteredResults.length; index++) {
        if(filteredResults[index].startsWith('M')) {
          setCurrentMac(filteredResults[index]);
        } else if(filteredResults[index].startsWith('G')) {
          setCurrentMac("");
          setLoraMac(filteredResults[index])
        }
          
      }
      //setCurrentMac(filteredResults[0]);


      let dataMacCan1 = [];
      let dataMacDashboard = [];
      await Promise.all(
        listColl.data.collections.map(async (itemMac) => {
          const addrCan = usuario.username + "/infoDevices/" + itemMac;
          const Vcan = await db.collection(addrCan).get();
          //dataMacCan1=[...dataMacCan1,...Vcan.docs.map(doc=>({...doc.data() }))]
          let itemPush = {
            mac: itemMac,
            cans: Vcan.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          };

          //console.log("Esto es itemPush:", itemPush);
          const cansObtained = Vcan.docs.map((doc) =>  doc.id )
          setNumberOfCans(cansObtained);
          // if(itemMac !== "undefined"){
          //   setCurrentMac(itemMac);
          // }
          



          if(usuario.username === "RicardoPGapy") {
            //const Mac = "MA4B0B5B5AA8C";
            const Mac = "MF0B3B5B5AA8C"
            if(itemPush.mac !== Mac) {
              await deleteSubcollection(usuario.username,"infoDevices",itemPush.mac);
            }
          }
          
          if(itemPush.mac === "LoraTest" || itemPush.mac === "G7CC09EDAD4D4") {
            console.log("LoraTest detectado");
            return
          } else{
          dataMacCan1.push(itemPush);
          dataMacDashboard.push({ lastNode: itemPush.cans.length - 1, cnt: 0 });
          }
        })
      );
      setDataMacCan(dataMacCan1);
      dataMacDashRef.current = dataMacDashboard;
      //console.log({ msg: "dataMacCan: ", dataMacCan1, dataMacDashRef });
    } catch (error) {
      console.log(error);
    }
  };

  const detectarUsuario = () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let rol = "";
        const res = await user.getIdTokenResult();
        //console.log(res.claims)
        if (!!res.claims.admin) {
          rol = "admin";
        }
        //else if(!!idTokenResult.claims.autor){rol="autor"}
        else {
          rol = "invitado";
        }

        let usuario = await getUserDataDB(user.email);
        usuario = { ...usuario, estado: true, rol };

        setUsuario(usuario);
      } else {
        setUsuario({ ...noUser, estado: false });
      }
    });
  };

  //Custom Claims- Verificar que funcione correctamente con el rol en FireStore
  const forzarLeecturaCustomClaims = () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await user.getIdToken(true); //forza a actualizarse desde el cliente
        console.log("Forzando Lectura Custom");
      }
    });
  };

  const cerrarSesion = () => {
    
    localStorage.clear();
    setDataMacCan([]);
    setDataMacCanDash([]);
    dataMacDashRef.current = [];
    auth.signOut();
  };

  const signUp = async (email, pass, username) => {
    try {
      const res = await auth.createUserWithEmailAndPassword(email, pass);

      const usuario = {
        email: res.user.email,
        uid: res.user.uid,
        username: username,
        estado: true,
        rol: "admin",
        photoURL: null,
      };

      await savingUser(usuario);
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithEmailAndPassword = async (email, pass) => {
    try {
      await auth.signInWithEmailAndPassword(email, pass);

      const user = await getUserDataDB(email);
      setLocalUser(user);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserDataDB = async (email) => {
    try {
      const usuarioDB = await db
        .collection("usuarios")
        .where("email", "==", email)
        .get();

      const user = {
        ...usuarioDB.docs.map((doc) => doc.data())[0],
        estado: true,
      };

      return user;
    } catch (error) {
      console.log(error);
    }
    return;
  };

  const inWithPopup = async (provider) => {
    try {
      const res = await auth.signInWithPopup(provider);

      const isSingUp = res.additionalUserInfo.isNewUser;

      if (isSingUp) {
        const usuario = {
          email: res.user.email,
          uid: res.user.uid,
          username: res.user.displayName,
          photoURL: res.user.photoURL,
          estado: true,
          rol: "admin",
        };
        await savingUser(usuario);
      } else {
        const user = await getUserDataDB(res.user.email);
        setLocalUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const savingUser = async (usuario) => {
    try {
      await setUserDB(usuario);
      setLocalUser(usuario);
    } catch (error) {
      console.log(error);
    }
  };

  const setLocalUser = (usuario) => {
    localStorage.setItem("usuario", JSON.stringify(usuario)); ///////////////////
    setUsuario(usuario);
  };

  const setUserDB = async (usuario) => {
    try {
      const { email, username } = usuario;
      await db.collection("usuarios").doc(username).set(usuario);
      await administrador(email, username, false);
    } catch (error) {
      console.error(error);
    }
  };

  const administrador = async (email, username, activateFilter) => {
    try {
      const agregarRol = functions.httpsCallable("agregarAdministrador");
      const res = await agregarRol({ email, activateFilter });
      //console.log(res)
      if (res.data.error) {
        console.log(res.data.error);
        return "invitado";
      }

      db.collection("usuarios").doc(username).update({ rol: "admin" });
      //console.log('Admin creado')
      return "admin";
    } catch (error) {
      console.log("error");
      return "invitado";
    }
  };

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        if (dataMacCan.length > 0) {
          const namesTemp = [];
          const irrigationTypeConfig = [];

          await Promise.all(dataMacCan.map(async (itemMac) => {
            if(itemMac.mac !== "undefined") {            
              await Promise.all(itemMac.cans.map(async (itemCan) => {
                const addr = `${usuario.username}/infoDevices/${itemMac.mac}/${itemCan.id}/configModule`;

                if (itemCan.type === '1') {
                  setCanIdIrrigation(itemCan.id);
                  const collectionRef = db.collection(addr);
                   // Obtenemos los documentos que cumplan con el criterio
                  const snapshot = await collectionRef.get();

                  snapshot.forEach(doc => {
                    const docId = doc.id;
                    // Verificamos que el nombre del documento contenga "5" en la posición correcta
                    const parts = docId.split('@');
                    if (parts[2] === '5') {
                      const index = parseInt(parts[3]); // Asignamos el índice
                      // Extraemos el valor deseado del documento
                      const valueToExtract = doc.data().item.name; 
                      const typeConfig = doc.data().irrigationSetup
                      namesTemp[index] = valueToExtract; 
                      irrigationTypeConfig[index] = typeConfig;
                    }
                  });
                }
              }));
            }
          }));
          setNames(namesTemp);
          setConfigIrrigation(irrigationTypeConfig)
        }
      } catch (error) {
        console.error("Error al obtener datos: ", error);
      }
    };
    obtenerDatos();
  }, [usuario.username, dataMacCan ]); // Dependencias del useEffect

  const updateNames = (index, name) => {
    const helpArray = [...names];
    helpArray[index] = name;
    //console.log("Esto es helpArray:",helpArray)
    setNames([...helpArray])
  }

  return (
    <UserContext.Provider
      value={{
        usuario,
        setUsuario,
        signUp,
        signInWithEmailAndPassword,
        inWithPopup,
        cerrarSesion,
        dataMacCan,
        administrador,
        getArrayMac,
        dataMacCanDash,
        setDataMacCanDash,
        dataMacDashRef,
        numberOfCans,
        currentMac,
        names,
        canIdIrrigation,
        configIrrigation,
        setUpdateNamesFlag,
        updateNames,
        loraMac
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
