import React from 'react'
import DosSelect from './ConfigComponents/DosSelect'
import {db} from '../../../../config/firebase'

import moment from 'moment'
import 'moment/locale/es'
import {UserContext} from '../../../../context/UserProvider'
import UpdatingButton from './ConfigComponents/UpdatingButton'
import DialogConfirm from './ConfigComponents/DialogConfirm'
import TransitionAlerts from '../../../Common/TransitionAlerts'
import {backGroundList} from '../../../../constants/globalConst'

const actionLink="sendConfig"
const codifyRules=(comp)=>{
    const dataMqtt=[comp.canid,comp.outid,comp.type];
    const actionMqtt=2;//0 ConfigData,1 ConfigHyT,2 Config Seco
    const MSarray=[dataMqtt.length,actionMqtt,...dataMqtt];
    let MS=MSarray[0]+'';
    for (let i = 1; i < MSarray.length; i++) {
        //array[i]=parseInt(MSarray[i]);
        MS=MS+','+MSarray[i];
        }
    return MS;
}

const defaultOpenAlert={open:null,
    type:null,
    tittle:null,
    txt:null}


const ConfigDigitalInput = (propiedades) => {
    const props=propiedades.data;
    //console.log("props",props)
    
    const ide=props.ide;//es el id que se muestra en el navegador SW@x
    const [comp,setComp]=React.useState(null)
    
    const [uid,setUid]=React.useState("");
    //const [selects,setSelects]=React.useState([])
    const [error,setError]=React.useState(null)
    const {usuario}=React.useContext(UserContext)
    const [nodeNameCache,setNodeNameCache]=React.useState()

    const [loading,setLoading]=React.useState(null)//Loading es para cargar , esto muestra el ciruclo cargando
    const [openWindow, setOpenWindow] = React.useState(false);
    const [openAlert,setOpenAlert]=React.useState(defaultOpenAlert)//Para abrir la Alerta de notificaciones
    const [dateSnap,setDateSnap]=React.useState("0")


    React.useEffect(()=>{


       const obtenerDatos=async()=>{
        try{

        const leyendoDBSwitch=async(nameUid,addr)=>{
          const data=await db.collection(addr).doc(ide).get();
          const actualItem={id: data.id,...data.data()}
          localStorage.setItem(nameUid,JSON.stringify(data.data().item))
          setComp(actualItem.item)//Toda la informacion del componente

          
          return actualItem.item
        }

        const arrayDeCadenas = ide.split("@");
        const mac=arrayDeCadenas[0]
        const canid=arrayDeCadenas[1]
        const nameUid=`Config@${ide}`
        const addr=`${usuario.username}/infoDevices/${mac}/${canid}/configModule`
         
        setNodeNameCache(nameUid)
        setUid(ide)//uid de la base de datos

        let actualItem={}
        if(localStorage.getItem(nameUid)){
                console.log("Leyendo desde cache")
                actualItem=JSON.parse(localStorage.getItem(nameUid))
                setComp(actualItem)
            }else{
                console.log("Leyendo desde base de datos")
                actualItem=await leyendoDBSwitch(nameUid,addr);  
            }
        
        console.log("SWC1") 
          
          

        }catch(error){
          console.log(error)
        }
      }
      obtenerDatos()
        
        
      },[ide,usuario.username]);
    
    

    const onDosSelect=React.useCallback((e)=>{
        //setTipo(e.value)
        setComp({...comp,type:e.value})
    },[comp]);


   




    const editar=async(e)=>{
        e.preventDefault()
        console.log("object")
        
        if(!comp.name.trim()){
          setError("Ingrese Nombre")
          return
        }
        setError(null)
        setOpenWindow(true)

       
      }
    
    const agreeDialog=async()=>{//Aun no guarda solo manda a cloudfunctions para mandar a IOT
        //const item={...node, fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
        setLoading(true)

        
        
        const item={
            msMqtt:codifyRules(comp),
            mac:comp.mac,
            action:actionLink,
            fecha:Date.now(),
            uid:nodeNameCache
        }
        
        
        try {
            const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            await db.collection(addr).doc("sendConfig").set({item})
            setOpenWindow(false)//Solo abre la ventana
            
        } catch (error) {
        console.log(error)
        } 
    }




        /* Notifica cuando se a terminado de configurar y cambia la bandera, loading permite modificar o cambiar*/ 
        React.useEffect(()=>{

            const isTheUpdated=async()=>{
                const addr1=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
                const data=await (await db.collection(addr1).doc("sendConfig").get()).data()
                if(data.item.uid!==nodeNameCache){return false}
                else {return true}
            }
            
            const ChangeFireStore= async() => {
                const add=usuario.username+'/infoDevices/'+comp.mac+'/'+comp.canid+'/fromModule';
                
                await db.collection(add).where('act', '==', 'recOK')
                .onSnapshot(querySnapshot => {
                    querySnapshot.docChanges().forEach(async change => {
                        
                    if (change.type === 'modified') {
                        const breaking=await isTheUpdated()
                        if(breaking===false){return}
    
                        setLoading(false)//Una ves que llega el mensaje desactiva el bloqueo, el loading
                        setOpenAlert({
                            open:true,
                            type:"success",
                            tittle:"Configuración Exitosa",
                            txt:"El modulo se configuro correctamente",
                        })

                        const dataTime=change.doc.data().time
                        console.log("Snap") 
                        setDateSnap(dataTime)
                        /*

                        const action=[0,0,0,0,0,0,0,0];
                        selects.forEach(i=>{action[i.outid]=i.value;});
                        
                        const item={
                            ...comp,
                            action,
                            fecha:Date.now(),
                            msMqtt:codifyRules(comp,action)
                        }    
                        const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`
                        await db.collection(addr).doc(uid).update({item})
                        localStorage.setItem(nodeNameCache,JSON.stringify(item))
                        setComp(item)
                        console.log("Salvando en DB switch")
                        */


    
                    }
                    
                    });
                });
            }
            if(comp && usuario.username){
                ChangeFireStore();
            }
            
        },[comp,usuario.username,nodeNameCache])
        
        

        React.useEffect(()=>{
            const savingDB=async()=>{
                
                /*
                const action=[0,0,0,0,0,0,0,0];
                selects.forEach(i=>{action[i.outid]=i.value;});
                */
                const item={
                    ...comp,
                    //action,
                    fecha:Date.now(),
                    msMqtt:codifyRules(comp)
                }    
                const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`
                await db.collection(addr).doc(uid).update({item})
                localStorage.setItem(nodeNameCache,JSON.stringify(item))
                setComp(item)
                
                
    
                console.log("GuardandoDB",comp,nodeNameCache,uid,usuario.username)
                
            }
    
            if(dateSnap!=="0"){savingDB()}
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateSnap])





        
    /* Notifica si Hubo un error o el mensaje cuando se envio al IoT*/ 
    React.useEffect(()=>{
        const ChangeFireStore= async() => {
            const add=usuario.username+'/logs/'+comp.mac;
            
            await db.collection(add).where('act', '==', 'sendConfigModule')
            .onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                    
                if (change.type === 'modified') {
                    //console.log()
                    const data=change.doc.data()
                    if(data.action!==actionLink){return} 
                    if(data.uid!==nodeNameCache){return}//si este uid no fue el que se actualizo no cambia parametros
                    let alertjs={}
                    switch(data.state){
                        case "Error":
                            alertjs={
                                open:true,
                                type:"error",
                                tittle:"Error",
                                txt:"Hubo un error al enviar: Verifique la conexión a internet de GAPY-Master",
                            }
                            break;
                        case "Info":
                            alertjs={
                                open:true,
                                type:"info",
                                tittle:"Info",
                                txt:"Espere a que se configure correctamente GAPY-MASTER",
                            }
                            break;
                        default:
                            alertjs=defaultOpenAlert;
                            break;
                    }
                    setOpenAlert(alertjs)
                }
                
                });
            });
        }
        
        if(comp && usuario.username){
            ChangeFireStore();
        }
    },[comp,usuario.username,setOpenAlert,nodeNameCache])


    
    const close=(e)=>{
        //console.log(e)
        if(openAlert.type==="error"){
            setLoading(false)
        }
        setOpenAlert(defaultOpenAlert)
    }



    return comp&&(
        <div className="container">
            <form onSubmit={editar}>
                    
                <div className="row">

                    <div className="col-lg-4 col-md-12 mt-3">
                        <ul className="list-group">
                            <li className="list-group-item" style={{background:backGroundList}}>
                                <h5 style={{ color: "white" }}>Switch</h5>
                                
                            </li>
                    
                            <li className="list-group-item" >

                                <div className="row">
                                    <div className="col-5">
                                    Name: 
                                    </div>
                                    <div className="col-7">
                                        <input
                                        type="text"
                                        placeholder="Ingrese Nombre"
                                        className="form-control mb-2"
                                        onChange={e=> setComp({...comp,name:e.target.value})}
                                        value={comp.name}
                                        ></input>
                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                    Out: 
                                    </div>
                                    <div className="col-7">
                                    {comp.outid}
                                    </div>
                                </div>
                                

                                <div className="row">
                                    <div className="col-5">
                                    MAC: 
                                    </div>
                                    <div className="col-7">
                                    {comp.mac}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        CAN: 
                                    </div>
                                    <div className="col-7">
                                        {comp.canid}
                                    </div>
                                </div>

                                   {
                                        comp.fecha&&(
                                            <div className="row">
                                                <div className="col-5">
                                                    Last Update: 
                                                </div>
                                                <div className="col-7">
                                                {moment(comp.fecha).format('llll')}
                                                </div>
                                            </div>
                                            
                                        )
                                    }

                            </li>
                        </ul>
                        
                    </div>

                    <div className="col-lg-8 mt-3">
                            <li className="list-group-item" style={{background:backGroundList}}>
                                <h5 style={{ color: "white" }}>Configuración - {comp.name}</h5>
                            </li>



                            
                        <ul className="list-group">
                            <li className="list-group-item" >

                                <div key="Tipo">
                                    <DosSelect
                                    id="0"
                                    label="Tipo"
                                    label0="NO"
                                    label1="NC"
                                    value={comp.type}
                                    handleToggle={onDosSelect}
                                    />
                                </div>


                            </li>
                        </ul>
                        
                    </div>
                </div>

                <div className="row">
                        {//como operador ternario
                         error && (
                             <div className="col-12 mt-3">
                                <div className="alert alert-danger alert-block">
                                 {error}
                                </div>

                             </div>
                             
                         )
                         }
                </div>
                <UpdatingButton
                    type="submit" 
                    disabled={loading}
                />  

            </form>



            <DialogConfirm
                open={openWindow}
                handleClose={()=>setOpenWindow(false)}
                agreeDialog={agreeDialog}
                title={"¿Está seguro que quiere continuar con esta operación?"}
                text={
                "Permite realizar configuraciones de la salidas o entradas del Nodo. Verifique que el Nodo que desea configurar este conectado através del GAPY-BUS"}

            />

            
            <TransitionAlerts
            data={openAlert}
            onClick={close}
            />

            
        </div>
    )
}

export default ConfigDigitalInput
