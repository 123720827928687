import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import ConfigButton from "./ConfigButton";
import CrossButton from "./CrossButton";
import { DashboardContext } from "../../../../context/DashboardProvider";

const HeadTile = ({ name, uid, col }) => {
  const { isDragButton, moveTileToMain } = useContext(DashboardContext);
  return (
    <Grid container>
      <Grid item xs={8}>
        <h5>{name}</h5>
      </Grid>

      <Grid item xs={3}>
        <ConfigButton
          to={`/config/S@${uid}`}
          disabled={col === 0 ? true : false}
        />
      </Grid>

      <Grid item xs={1}>
        <CrossButton onClick={() => moveTileToMain(uid)} hidden={isDragButton || col === 0} />
      </Grid>
    </Grid>
  );
};

export default HeadTile;
