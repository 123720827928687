import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings';
import {withRouter} from 'react-router-dom'

const ConfigButton = (props) => {
    return (
        <div>
            <IconButton 
            aria-label="config" 
            onClick={()=>{props.history.push(props.to)}}
            disabled={props.disabled?props.disabled:false}
            style={{bottom: 20}}
            >
                <SettingsIcon fontSize='large'/>
            </IconButton>
        </div>
    )
}

export default withRouter(ConfigButton)
