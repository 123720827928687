import React, { useCallback, useEffect, useState, useContext } from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useWindowDimensions from "./ComponentsDashboard/WindowDimensions";
import { UserContext } from "../../../context/UserProvider";
import { DashboardContext } from "../../../context/DashboardProvider";
import DashBoardHead from "./ComponentsDashboard/DashBoardHead";

import {
  getNewPositionsWhenTileMoved,
  requestDataFirstNodes,
  saveTilesPositions,
} from "./Functions";
import { BG_COLOR_TILES_WHEN_DRAG, BG_COLUMN_COLOR_DRAG_OFF, BG_COLUMN_COLOR_DRAG_ON, GRID } from "./constants";
import { db } from "../../../config/firebase";
import AppropriateTile from "./ComponentsDashboard/AppropriateTile";

const useStyles = makeStyles(theme => ({
  paperWidget: {
    width: 250,
    margin: theme.spacing(1),
  },
  gridItem: {
    minWidth: 250,
    maxWidth: "100%",
  },
}));

const DashBoard = () => {
  const classes = useStyles();
  const { usuario, dataMacCan, numberOfCans, currentMac } = useContext(UserContext);
  const { allTiles, setAllTiles, isDragButton, setIsDragButton } = useContext(DashboardContext);
  const { username } = usuario;
  const [tilesMoved, setTilesMoved] = useState(false);
  const [isHorizontalMenuVisible, setIsHorizontalMenuVisible] = useState(false);
  const { width } = useWindowDimensions();

  const minutos = 60000 * 2; //60000 ms = 1 minuto

  const toggleDragMode = async () => {
    if(isHorizontalMenuVisible || tilesMoved){
      setTilesMoved(false);
      await saveTilesPositions(allTiles, username);
    }
    setIsDragButton(!isDragButton);
    setIsHorizontalMenuVisible(!isHorizontalMenuVisible);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
  
    // Actualiza allTiles usando la función mejorada para manejar movimientos entre y dentro de columnas
    const updatedTiles = getNewPositionsWhenTileMoved(source, destination, allTiles);
  
    setAllTiles(updatedTiles);
    setTilesMoved(true);
  };

  useEffect(() => {
    
    const sendMessageToFirebase = async (routeId, message) => {
      try {
        if (currentMac) {
          const addr = `${username}/infoDevices/${currentMac}/${routeId}/sendConfigModule`;
          await db.collection(addr).doc("sendConfig").set({ item: message });
        }
      } catch (error) {
        console.error('Error sending message to Firebase:', error);
      }
    };

    const sendMessages = () => {
      numberOfCans.forEach((id, index) => {
        setTimeout(() => {
          const item = {
            action: "askingData",
            fecha: Math.floor(Date.now() / 1000),
            mac: currentMac,
            msMqtt: `2,6,${id},1`,
            uid: `${currentMac}@${id}`
          };
          //console.log("Esto es item:", item);
          //console.log("Esto es currentMac:",currentMac)
          sendMessageToFirebase(id, item);
        }, 5000 * index); // Desfase de 5 segundos entre cada mensaje
      });
    };

    if (numberOfCans.length > 0) {
      sendMessages(); // Enviar los mensajes inmediatamente
      //console.log("Esto es currentMac:", currentMac)
      //console.log("Esto es numberOfCans:",numberOfCans)
      const intervalId = setInterval(() => {
        sendMessages();
      }, minutos); // Repetir cada 'minutos' milisegundos (2 minutos en tu caso)

      return () => clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonte
    }
    
  }, [numberOfCans, currentMac, username, minutos]);



  return (
    <div className="container mt-5">
      <DashBoardHead isDragButton={isDragButton} onClickSaveDragged={toggleDragMode} username={username} mac={currentMac} />

      <div style={{ marginTop: '32px' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {isHorizontalMenuVisible && (
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{ padding: `${GRID * 2}px 0` }}>
              <Droppable droppableId={`${0}`} direction="horizontal">
                {(provided) => (
                  <Grid 
                  item 
                  ref={provided.innerRef} 
                  style={{background: BG_COLUMN_COLOR_DRAG_OFF,
                  padding: GRID,
                  overflow: "auto",
                  alignItems: "center",
                  display: "flex",
                  width: width < 650 ? "300px" : "",
                  border: width < 650 ? "2px dotted" : "",
                  minWidth:"300px",
                  minHeight:"50px"}}
                  {...provided.droppableProps} 
                  >
                    {allTiles[0]?.map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={isDragButton}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              
                            }}
                            className={classes.paperWidget}
                          >
                            <AppropriateTile item={item} index={index} col={0} />
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          )}

          <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
            {Array.from({ length: 3 }, (_, i) => i + 1).map(i => (
              <Droppable key={i} droppableId={`${i}`}>
                {(provided) => (
                  <Grid item
                  ref={provided.innerRef} 
                  style={{
                    background: BG_COLUMN_COLOR_DRAG_OFF,
                    padding: GRID,
                    border: width < 650 ? "2px dotted" : "",  //"2px dotted"
                    minWidth:"300px",
                    minHeight:"50px"}}
                  {...provided.droppableProps} 
                  
                  >
                    
                    {allTiles[i]?.length ?
                      allTiles[i].map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={!isHorizontalMenuVisible}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              
                            }}
                            className={classes.paperWidget}
                          >
                            
                            <AppropriateTile item={item} index={index} col={i} />
                          </Paper>
                        )}
                      </Draggable>
                    ))
                    :
                    <div style={{ textAlign: 'center', width: '100%' }}>Cargando...</div>
                    }
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            ))}
          </Grid>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DashBoard;

