import React, { useEffect, useState } from 'react'
import { db } from '../../../config/firebase';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import downloadPageIcon from '../../../assets/icons/greenG-downloadDoc-icon.png'




const useStyles = makeStyles((theme) => ({
    formField: {
      margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
      },
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    input: {
      // Ajusta el tamaño del input y otros estilos aquí
      width: '100%', // Hace que el input ocupe todo el ancho del contenedor
      padding: theme.spacing(1), // Usa el sistema de espaciado de Material-UI para el padding
      [theme.breakpoints.down('sm')]: {
        // Ajustes para pantallas pequeñas (dispositivos móviles)
        padding: theme.spacing(0.5),
      },
    },
    buttonProgress: {
      color: green[500],
       marginLeft: 12,
    },
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
  }));
  

export const HistoricalChats = ({username}) => {
    const classes = useStyles();
    const [selectChat, setSelectChat] = useState('');
    const [optionsChats, setOptionsChats] = useState(null);
    const [startDownload, setStartDownload] = useState(false);
    const [documentUrl, setDocumentUrl] = useState();

   
    const handleSelectedChat = (e) => {
      setSelectChat(e.target.value);
    }


    const handleDownloadChat = async () => {
      const dataJson = {
        username: username,
        nameChat: selectChat
      }
      setStartDownload(true);
      try {
        const dataToSend = JSON.stringify(dataJson);
        const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/getHistoricalChats', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: dataToSend,
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        //console.log("URL de descarga:", jsonData.url);
        setDocumentUrl(jsonData.url);
        //alert('Comenzo la descarga Gappier.');
        setStartDownload(false);
        
      } catch (error) {
        console.error("No se pudo realizar la peticion de descarga:", error)
      }
      //console.log("Esto es dataJson:", dataJson);
    }

    useEffect(() => {
      const addr = `${username}`;
      const unsubscribe = db.collection(addr).doc('japyChats')
        .onSnapshot(doc => {
          if (doc.exists) {
            //console.log("Datos del documento:", doc.data());
            const dataHistory = doc.data();
            setOptionsChats(dataHistory.historyChats);
          } else {
            console.log("No se encontró el historial de chats!");
          }
        });
  
      // Limpiar el observador cuando el componente se desmonte
      return () => unsubscribe();
    }, [username]);


  return (
    <>
    <div style={{ marginTop: '15px' }}>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Typography variant="h6" className={classes.title}>
            Descarga tus Chats
            </Typography>
        </Grid>
    </div>

    <Grid container justifyContent='center' alignItems='center'>
      <img style={{width: '25%'}} src={downloadPageIcon} alt="Download Page Icon" />
    </Grid>

    <div style={{ marginTop: '15px' }}>
      <Grid container justifyContent='flex-start' alignItems='flex-start'>
        <Typography >
            {"Selecciona el chat para descargar:"}
        </Typography>
      </Grid>        
    </div>

    <div style={{ marginTop: '10px' }}>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        {optionsChats === null ? 
          <Typography >
            {"No hay chats todavia. Realiza una consulta a Japy"}
          </Typography>
        :      
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="demo-simple-select-label">Chats</InputLabel>
          <Select
            labelId="chats-selector"
            id="chats-selector"
            value={selectChat}
            onChange={handleSelectedChat}
          >
            {optionsChats.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        }
      </Grid>
      
    </div>

    <div style={{ marginTop: '15px' }}>
       {documentUrl && <a href={documentUrl}>Click aquí para descargar tu archivo</a>} 
    </div>

    <div style={{ marginTop: '15px' }}>
      <Grid container justifyContent='center' alignItems='center'>
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={handleDownloadChat}
          disabled={ selectChat === '' || startDownload}
        >
          Descargar Chat
        </Button>
        {startDownload && <CircularProgress size={30} className={classes.buttonProgress} />}
      </Grid>
    </div>
    </>
  )
}
