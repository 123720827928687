import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { MeasureContext } from "../../../../../../context/MeasureProvider";

import moment from "moment";
import "moment/locale/es";
import ButtonMenu from "./ButtonMenu";
import ButtonUploadMeasure from "./ButtonUploadMeasure";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

Row.propTypes = {
  row: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        comment: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        date: PropTypes.number.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function Row(props) {
  const { row } = props;
  const { handleClickOpenDialog, handleClickDeleteRow } =
    React.useContext(MeasureContext);

  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="h6" gutterBottom component="div">
            {row.name}
          </Typography>
        </TableCell>
        <TableCell>
          <ButtonUploadMeasure
            handleClickOpenDialog={(e) => handleClickOpenDialog(e, [])}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Histórico
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Config</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell align="right">Comentario</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.data.map((dataRow, i) => (
                    <TableRow key={`${dataRow.date},${i}`}>
                      <TableCell component="th" scope="row">
                        <ButtonMenu
                          handleClickOpenDialog={(e) =>
                            handleClickOpenDialog(e, dataRow)
                          }
                          handleClickDeleteRow={(e) =>
                            handleClickDeleteRow(e, dataRow)
                          }
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {moment(dataRow.date).format("llll")}
                      </TableCell>
                      <TableCell>{dataRow.value}</TableCell>
                      <TableCell align="right">{dataRow.comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Row;
