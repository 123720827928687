import { Grid, TextField } from '@material-ui/core'
import React from 'react'

export const MasterModuleForm = ({ parametersValues }) => {
  return (
    <Grid container  
        justifyContent="center"
        alignItems="center" 
        spacing={2}>
          <Grid item xs={12} sm={6}>
          <TextField 
                margin="dense"
                id="humedad"
                label="Humedad"
                value={parametersValues.Humedad}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="temperatura"
                label="Temperatura"
                value={parametersValues.Temperatura}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="par"
                label="Par"
                value={parametersValues['Sensor PAR']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="temp_hoja"
                label="Temepratura de la hoja"
                value={parametersValues['Temperatura de la hoja']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="co2"
                label="CO2"
                value={parametersValues.C02}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="ph"
                label="pH"
                value={parametersValues.ph}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="ec"
                label="EC"
                value={parametersValues.Ec}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="temp_agua"
                label="Temperatura del agua"
                value={parametersValues['Temperatura del Agua']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>
          
        </Grid>
    
  )
}
