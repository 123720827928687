import React from 'react'
import { FormControl, MenuItem, Select, } from '@material-ui/core';

const SelectMultiple = (props) => {
    const id = "Select" + props.id;

    return (
        <div>
            <FormControl fullWidth>
                <Select
                    name={props.id}
                    id={id}
                    value={props.value}
                    label=""
                    onChange={(e) => props.handleToggle(e.target)}
                >
                    {
                        props.arrayOptions.map((item, i) =>
                            <MenuItem value={item.value} key={i}>{item.name}</MenuItem>
                        )
                    }


                </Select>
            </FormControl>
        </div>
    )
}

export default React.memo(SelectMultiple)
