import React, { useState } from 'react';
import { Modal, Button, IconButton, Dialog, useMediaQuery, useTheme, DialogContentText, DialogContent, TextField, DialogActions, Grid, Typography, DialogTitle } from '@material-ui/core';
import ChatBotForm from '../../ChatBotForm/ChatBotForm';
import { makeStyles, withStyle } from '@material-ui/core/styles';
import { CloseOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: '50vw', // Asegura un mínimo de ancho para el diálogo
    maxWidth: '50vw', // Un máximo para evitar que sea demasiado grande
    [theme.breakpoints.down('xs')]: {
      minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));



const PopupForm = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const sparklesIcon = '/sparkles-svgrepo-com-Mini.svg';
  
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSVGPress = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div>
        <IconButton onClick={handleSVGPress}>
          <img style={{width: '100%'}} src={sparklesIcon} alt="SVG Icon" />
        </IconButton>
        
        <Dialog 
        // fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }} 
        >
          <DialogTitle id="customized-dialog-title">{" Tu asistente de cultivo inteligente Japy"}
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
        
     
          <DialogContent>

            <ChatBotForm />

          </DialogContent>

        </Dialog>

      </div>

    </>
  );
};

export default PopupForm;