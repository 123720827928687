import { Grid, TextField } from '@material-ui/core'
import React from 'react'

export const GatewayLoraForm = ({ parametersValues }) => {
  return (
    <Grid container  
        justifyContent="center"
        alignItems="center" 
        spacing={2}>
          <Grid item xs={12} sm={6}>
          <TextField 
                margin="dense"
                id="humedad_A"
                label="Humedad Ambiental"
                value={parametersValues['Humedad ambiental']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="temperatura_A"
                label="Temperatura Ambiental"
                value={parametersValues['Temperatura ambiental']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="co2"
                label="CO2"
                value={parametersValues.CO2}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="temp_Sus"
                label="Temperatura Sustrato"
                value={parametersValues['Temperatura Sustrato']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="hum_Sus"
                label="Humedad Sustrato"
                value={parametersValues['Humedad Sustrato']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
                margin="dense"
                id="ph_sus"
                label="pH Sustrato"
                value={parametersValues['pH Sustrato']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField 
                margin="dense"
                id="ec_sus"
                label="EC Sustrato"
                value={parametersValues['EC Sustrato']}
                type="number"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                multiline={true}
              />
          </Grid>
          
        </Grid>
  )
}
