import React from 'react';
  import {
    continuosSensors,
    discreteSensors,
    discreteOuts,
    ALL_KINDS,
  } from "../../../../constants/globalConst";
  import { VIDEO_ITEM, REMINDER_ITEM } from "../Functions";
  
  import ContinousSensorTile from "./ContinousSensorTile";
  import DiscreteSensorTile from "./DiscreteSensorTile";
  import DiscreteOutTile from "./DiscreteOutTile";
  import HeadForAsyncTile from "./HeadForAsyncTile";
  import WeekDaysTile from "./WeekDaysTile";
  import PWMSlider from "./PWMSlider";
  import { NodeLoraTile } from "./NodeLoraTile";
  import { Grid } from '@material-ui/core';
import TestContinousSensorTile from './TestContinousSensorTile';
  
  const AppropriateTile = React.memo(({ col, item, onClickCrossButton }) => {
    /* if (!item) {
      return <div>Loading...</div>;  // O manejar el caso de undefined adecuadamente
    } */
    
    const { kind } = item;
    const style = { width: "250px" };
    const { OUT_PWM } = ALL_KINDS;
    
  
    switch (kind) {
      case OUT_PWM:
        return (
          <PWMSlider
            col={col}
            data={item}
            onClickCrossButton={onClickCrossButton}
            style={style}
            kind={kind}
          />
        );
      case VIDEO_ITEM:
        return (
          <HeadForAsyncTile
            col={col}
            onClickCrossButton={onClickCrossButton}
            style={style}
            name="Video"
          />
        );
      case REMINDER_ITEM:
        return (
          <HeadForAsyncTile
            col={col}
            onClickCrossButton={onClickCrossButton}
            style={style}
            name="Reminder"
          />
        );
      case "nodeLora":
        return (
          <NodeLoraTile 
            col={col}
            data={item}
            onClickCrossButton={onClickCrossButton}
            style={style}
          />
        );
      case "ghost":
        return (
          <Grid>

          </Grid>
        );
        case "D":
          return <WeekDaysTile 
          col={col}
          data={item}
          onClickCrossButton={onClickCrossButton}
          style={style}  
          />;
      default:
        if (continuosSensors.includes(kind)) {
          return <TestContinousSensorTile col={col} data={item} style={style} />;
        } else if (discreteSensors.includes(kind)) {
          return <DiscreteSensorTile {...{ col, data: item, onClickCrossButton, style }} />;
        } else if (discreteOuts.includes(kind)) {
          return <DiscreteOutTile {...{ col, data: item, onClickCrossButton, style, kind }} />;
        } 
    }
  });
  
  export default AppropriateTile;
  
