import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import UserProvider from "./context/UserProvider";
import { IrrigationProvider } from "./context/IrrigationContext";

ReactDOM.render(
  <UserProvider>
    <IrrigationProvider>
      {console.log("V2.0.3")}
      <App />
    </IrrigationProvider>
  </UserProvider>,
  document.getElementById("root")
);
