import React, { useState, useEffect, useContext, useCallback } from "react";
import { UserContext } from "../../../../context/UserProvider";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper } from "@material-ui/core";
import HeadTile from "./HeadTile";
import { HIGHCHART_OPT_SPANISH, continuosSensors } from "../../../../constants/globalConst";
import { db } from "../../../../config/firebase";
import {
  get_Uid_Value_ForAnalog,
  getDataFromDB,
} from "../../../../context/functions/DashboardFunctions/divideUIDByKind";
import { DashboardContext } from "../../../../context/DashboardProvider";

const useStyles = makeStyles({
  paperGraphics: {
    width: "215px",
    height: "170px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifycontent: "center",
    background: "transparent",
  },
  minmaxText: {
    color: "#ACACAC",
    fontSize: "8px",
  },
  noGraphText: {
    color: "#ACACAC",
  },
  titleGraph: {
    color: "#000",
    fontSize: "30px",
  },
  subtitleGraph: {
    color: "#5BB033",
    fontSize: "20px",
  },
});

const TestContinousSensorTile = ({
  col,
  data,
  style,
}) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const classes = useStyles();
  const [minVal, setMinVal] = useState(Infinity);
  const [maxVal, setMaxVal] = useState(-Infinity);
  const [valArrayChart, setValArrayChart] = useState([]);
  const { name, uid } = data;
  const [value, setValue] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [isDataFlag, setIsDataFlag] = useState(false)
  
  useEffect(() => {
    Highcharts.setOptions(HIGHCHART_OPT_SPANISH);

    const fetchAllData = async () => {
        try {
            const results = await getDataFromDB(uid, usuario.username);
            setValArrayChart([...results.valArray]);
            setMinVal(results.minVal);
            setMaxVal(results.maxVal);
        } catch (err) {
            console.error({ err });
            setValArrayChart([]);
            setMinVal(Infinity);
            setMaxVal(-Infinity);
        }
    };

    fetchAllData();
}, [uid, usuario.username]);

const updateChartData = useCallback((newValue) => {
  let newData = [...valArrayChart, [Math.floor(Date.now()), newValue]];
  const limit = 20;
  if (newData.length > limit) {
      newData = newData.slice(newData.length - limit);
  }
  setValArrayChart(newData);
}, [valArrayChart]);

useEffect(() => {
    const docPath = `${usuario.username}/infoDevices/${uid.split("@")[0]}/${uid.split("@")[1]}/fromModule/render`;
    const renderDoc = db.doc(docPath);

    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
        const data = docSnapshot.data();
        const resp = get_Uid_Value_ForAnalog({
            fromModuleActual: data,
            kind: uid.split("@")[2],
            outid: uid.split("@")[3],
            typeOfModule: dataMacCan.find((elmt) => elmt.mac === uid.split("@")[0]).cans.find((elmt) => elmt.id === uid.split("@")[1]).type,
        });

        if(resp.value === "-1.0") {
          setIsDataFlag(true);
          setPrevValue(resp.value);
        }else if (resp.value !== prevValue && resp.value >= 0.1) {
          setIsDataFlag(false);
          setValue(resp.value);
          setPrevValue(resp.value);
          updateChartData(parseFloat(resp.value));
        }
    });

    return () => unsubscribe();
  }, [uid, usuario.username, dataMacCan, prevValue, updateChartData]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile name={name} uid={uid} col={col} />
      <Grid item xs={5}>
        {isDataFlag ? "Sensor desconectado" :
        <h5><span className="badge badge-dark">{ value }</span></h5>
        }
      </Grid>
      {valArrayChart.length > 1 ? (
        <Paper className={classes.paperGraphics} elevation={0}>
          <Grid item xs={8}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                time: {
                    timezoneOffset: new Date().getTimezoneOffset(),
                },
                chart: {
                    height: "150px",
                    type: "area",
                    backgroundColor: "transparent",
                    borderRadius: 10,
                },
                series: [{
                  data: valArrayChart,
                  label: name,
                  name: name,
                  type: 'area',
                  color: '#5BB033',
                  fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                      [0, Highcharts.color("#5BB033").setOpacity(0.5).get("rgba")],
                      [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")]
                    ]
                  }
                }],
                title: {
                    text: "",
                    margin: 0,
                },
                xAxis: {
                    type: "datetime",
                    lineColor: "transparent",
                    labels: {
                      enabled: false,
                    },
                },
                yAxis: [
                    {
                      gridLineColor: "transparent",
                      title: {
                        align: "high",
                        offset: 0,
                        text: "",
                        rotation: 0,
                        y: -10,
                      },
                      labels: {
                        enabled: false,
                      },
                    },
                    {
                      gridLineColor: "transparent",
                      title: {
                        align: "high",
                        offset: 0,
                        text: "",
                        rotation: 0,
                        y: -10,
                      },
                      labels: {
                        enabled: false,
                      },
                    },
                  ],
                  legend: {
                    enabled: false,
                  },
                  credits: {
                    enabled: false,
                  },
                // Otras opciones de Highcharts...
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" justifycontent="space-between" alignItems="center" spacing={8}>
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>{maxVal}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>{minVal}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.paperGraphics} elevation={0}>
            <Typography variant={"h6"} className={classes.noGraphText}>
              Sin Gráfica
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TestContinousSensorTile, (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
});
