import React from 'react'
import DosSelect from '../ConfigComponents/DosSelect'
import InputNumber from '../ConfigComponents/InputNumber'
import { FormControl, Grid, InputLabel, Select } from '@material-ui/core'

const ConfigPumbAC = (props) => {
    const value=(data)=>{
        if(data===undefined){return [0,0,0,0,0,0,0,0,0,0,0]}
        else {

            for (let i = 0; i < data.length; i++) {
                data[i] = data[i].toString();
                
            }

            return data}
    }
    const data1=value(props.data.data1)
    //console.log(data1)
    return (
        <div>
                <h5>Control de Bomba</h5>

                <div className="row">
                    <div className="col-md-6">  
                        <InputNumber
                        id="HI"
                        label="Hora de Inicio"
                        value={props.horaInicio}
                        handleToggle={props.handdleHoraInicio}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputNumber
                        id="CH"
                        label="Cuantas Horas de Día"
                        value={props.cuantasHoras}
                        handleToggle={props.handdleCuantasHoras}
                        />
                    </div>
                </div>
                <h5>Control DÍA/NOCHE</h5>
                <hr/>



                <DosSelect
                id="1@0"
                label="Tipo de control durante el DÍA:"
                label0="Humedad Sustrato"
                label1="Temporizador"
                value={data1[0]}
                handleToggle={props.NumberD0_5}
                    />


                <div className="row">
                    
                    <div className="col-md-6">
                        
                        <InputNumber
                        id="1@1"
                        label={data1[0]==="0" ? "Humedad Menor a (%) ":"ON durante (min)"}
                        value={data1[1]}
                        handleToggle={props.NumberD0_5}
                        />
                    </div>

                    <div className="col-md-6">
                        
                        <InputNumber
                        id="1@2"
                        label={data1[0]==="0"? "Hasta que Humedad %":"OFF durante (min)"}
                        value={data1[2]}
                        handleToggle={props.NumberD0_5}
                        />
                    </div>

                </div>
                <hr/>




                <DosSelect
                id="1@3"
                label="Tipo de control durante la NOCHE:"
                label0="Humedad Sustrato"
                label1="Temporizador"
                value={data1[3]}
                handleToggle={props.NumberD0_5}
                    />


                <div className="row">
                    
                    <div className="col-md-6">
                        
                        <InputNumber
                        id="1@4"
                        label={data1[3]==="0"? "Humedad Menor a (%) ":"ON durante (min)"}
                        value={data1[4]}
                        handleToggle={props.NumberD0_5}
                        />
                    </div>

                    <div className="col-md-6">
                        
                        <InputNumber
                        id="1@5"
                        label={data1[3]==="0"? "Hasta que (%) ":"OFF durante (min)"}
                        value={data1[5]}
                        handleToggle={props.NumberD0_5}
                        />
                    </div>

                </div>

                {(data1[0]==="0" || data1[3]==="0") && 
                <>
                <hr/>
                <div style={{ marginTop: '20px',marginBottom: '20px' }}>
                    <Grid container 
                    direction='row'
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    >
                        Tomar mediciones del sensor:
                    </Grid>

                    <div style={{ marginTop: '20px',marginBottom: '50px' }} >

                        <FormControl fullWidth id={"selector"}>
                            <InputLabel id="select-sensor">Selecciona el sensor</InputLabel>
                            <Select
                                native
                                value={data1[10]}
                                onChange={(e)=>props.NumberD0_5(e.target)}
                                inputProps={{
                                    name: 'selectSensor',
                                    id: 'DosSelect1@10',
                                  }}
                            >
                                <option value={0}>Sensor AHT20 #1</option>
                                <option value={1}>Sensor AHT20 #1</option>
                                
                            
                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>

                        </FormControl>
                    </div>
                </div>
                </>
                }

                <h5>Dependencia de Sensores de Humedad de Sustrato</h5>
                    
                <DosSelect
                id="1@6"
                label="S. de Humedad Sustrato 1"
                label0="No"
                label1="Si"
                value={data1[6]}
                handleToggle={props.NumberD0_5}
                    />
                <DosSelect
                id="1@7"
                label="S. de Humedad Sustrato 2"
                label0="No"
                label1="Si"
                value={data1[7]}
                handleToggle={props.NumberD0_5}
                    />
                <DosSelect
                id="1@8"
                label="S. de Humedad Sustrato 3"
                label0="No"
                label1="Si"
                value={data1[8]}
                handleToggle={props.NumberD0_5}
                    />
                <DosSelect
                id="1@9"
                label="S. de Humedad Sustrato 4"
                label0="No"
                label1="Si"
                value={data1[9]}
                handleToggle={props.NumberD0_5}
                    />
                

        </div>
    )
}

export default ConfigPumbAC
