/* import React, { useState, useEffect, useContext, useRef } from "react";
import { db } from "../config/firebase";
import { UserContext } from "./UserProvider";
import { getAllUidConfigData } from "./functions/DashboardFunctions/getAllUidConfigData";
import { getAllTiles } from "./functions/DashboardFunctions/getAllTiles";
import { getAllNodesData } from "./functions/DashboardFunctions/sharedFunctions";
export const DashboardContext = React.createContext();

const DashboardProvider = (props) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const { username } = usuario;
  const [allTiles, setAllTiles] = useState([]);
  const [allUidConfigData, setAllUidConfigData] = useState([]);
  const [flgOnSnapShot, setFlgOnSnapShot] = useState(false);
  const [loraTiles, setLoraTiles] = useState(null);
  const isMountedRef = useRef(null);

  async function startGetAllTiles() {
    if (dataMacCan.length > 0) {
      getAllUidConfigData({
        username,
        dataMacCan,
      }).then((resp) => {
        const { dataConfiguracion, uidValueList } = resp;
        setAllUidConfigData(dataConfiguracion);
      });
    }
  }

  // useEffect(() => {
  //   // declare the async data fetching function
  //   const fetchData = async () => {
  //     if (allUidConfigData.length > 0) {
  //       const data = await getAllTiles({ allUidConfigData, username });
  //       setAllTiles(data);
  //     }
  //   };
  //   const getNodes = async () => {
  //     const configDataNodes = await getAllNodesData( username );

  //     if(configDataNodes === "No Lora Devices"){
  //       setLoraTiles(null);
  //     } else {

  //       setLoraTiles(configDataNodes);
  //     }
  //   }
    
  //   // console.log("ESto es configNodes:",configDataNodes);
  //   fetchData().catch(console.error);
  //   getNodes();
  // }, [allUidConfigData]);

  useEffect(() => {
    isMountedRef.current = true; // Indicar que el componente está montado

    // declare the async data fetching function
    const fetchData = async () => {
      if (allUidConfigData.length > 0) {
        const data = await getAllTiles({ allUidConfigData, username });
        setAllTiles(data);
      }
      console.log("Esto es allUidConfigData:", allUidConfigData);
      console.log("Esto es allTiles:", allTiles);
    };
  
    const getNodes = async () => {
      const configDataNodes = await getAllNodesData(username);
  
      if (configDataNodes === "No Lora Devices") {
        // Solo actualizar si el componente aún está montado
        if (isMountedRef.current) {
          setLoraTiles(null);
        }
      } else {
        // Solo actualizar si el componente aún está montado
        if (isMountedRef.current) {
          setLoraTiles(configDataNodes);
        }
      }
    };
  
    fetchData().catch(console.error);
    getNodes();
    // Función de limpieza al desmontar el componente
    return () => {
      isMountedRef.current = false; // Indicar que el componente se ha desmontado
    };
  }, [allUidConfigData]);

  useEffect(() => {
    setAllTiles([]);
    setAllUidConfigData([]);
  }, [usuario]);

  return (
    <DashboardContext.Provider
      value={{
        allTiles,
        setAllTiles,
        startGetAllTiles,
        allUidConfigData,
        flgOnSnapShot,
        setFlgOnSnapShot,
        loraTiles,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
  */


import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { UserContext } from "./UserProvider";
import { getAllUidConfigData } from "./functions/DashboardFunctions/getAllUidConfigData";
import { getAllTiles } from "./functions/DashboardFunctions/getAllTiles";
import { getAllNodesData } from "./functions/DashboardFunctions/sharedFunctions";

export const DashboardContext = React.createContext();

const DashboardProvider = ({ children }) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const { username } = usuario;
  const [allTiles, setAllTiles] = useState([]);
  const [allUidConfigData, setAllUidConfigData] = useState([]);
  const [loraTiles, setLoraTiles] = useState(null);
  const [isDragButton, setIsDragButton] = useState(true);
  const isMountedRef = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      if (dataMacCan.length > 0) {
        const configData = await getAllUidConfigData({ username, dataMacCan });
        if (configData) {
          setAllUidConfigData(configData.dataConfiguracion);
          const tiles = await getAllTiles({ allUidConfigData: configData.dataConfiguracion, username });
          setAllTiles(tiles);
        }
      }
    };

    const fetchLoraNodes = async () => {
      const configDataNodes = await getAllNodesData(username);
      if (isMountedRef.current) {
        setLoraTiles(configDataNodes === "No Lora Devices" ? null : configDataNodes);
      }
    };

    fetchData();
    fetchLoraNodes();

    return () => {
      isMountedRef.current = false;
    };
  }, [username, dataMacCan]);

  const moveTileToMain = useCallback((tileId) => {
    setAllTiles(prevTiles => {
        const newTiles = [...prevTiles]; // Crea una copia del estado actual
        let tileFound = null;
        let sourceIndex = null;

        // Buscar el tile en cada sub-arreglo
        for (let i = 0; i < newTiles.length; i++) {
            tileFound = newTiles[i].find(tile => tile.uid === tileId);
            if (tileFound) {
                sourceIndex = i; // Guardar el índice del sub-arreglo donde se encontró
                break; // Salir del ciclo una vez encontrado el tile
            }
        }

        if (tileFound && sourceIndex !== null) {
            // Eliminar el tile del sub-arreglo original
            newTiles[sourceIndex] = newTiles[sourceIndex].filter(tile => tile.uid !== tileId);
            // Añadir el tile al sub-arreglo en la posición 0
            newTiles[0].push(tileFound);
        }

        return newTiles;
    });
  }, [setAllTiles]);


  return (
    <DashboardContext.Provider value={{ allTiles, setAllTiles, loraTiles, isDragButton, setIsDragButton, moveTileToMain }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
 