import {
  VpnKey,
  FormatListNumbered,
  InsertInvitation,
  Power,
  Timeline,
  ImportContacts,
  AccountTree,
  Waves,
  Assistant
} from "@material-ui/icons";

import PublishIcon from "@material-ui/icons/Publish";

import {
  DashboardWCtx,
  ConfigIO,
  Graph,
  Token,
  InfoUser,
  Manual,
  IOList,
  Tareas,
  TreeChart,
  Calendar,
  Timelapse,
  Crop,
  NewDataByCSV,
  IrrigationForm,
} from "../components";

const PATH_ADMIN = "/admin";
const PATH_LINK = "/link";
const PATH_CALENDAR = "/calendar";
const PATH_IO_LIST = "/io_list";
const PATH_GRAPH = "/graph";
const PATH_BINNACLE = "/binnacle";
const PATH_INDEX = "/dashboard";
const PATH_DASHBOARD = "/dashboard";
const PATH_TIMELAPSE = "/timelapse";
const PATH_TIMELAPSE_ID = `${PATH_TIMELAPSE}/:crop`;
const PATH_TREECHART = "/system";

const PATH_CONFIG = "/config";
const PATH_CONFIG_ID = `${PATH_CONFIG}/:id`;
const PATH_INFO = "/info";
const PATH_NEWDATABYCSV = "/newdatabycsv";

const PATH_LOGIN = "/login";

const PATH_RIEGO = "/irrigation-routine";

const ALL_PATHS = {
  PATH_ADMIN,
  PATH_LINK,
  PATH_CALENDAR,
  PATH_IO_LIST,
  PATH_GRAPH,
  PATH_BINNACLE,
  PATH_INDEX,
  PATH_DASHBOARD,
  PATH_CONFIG,
  PATH_CONFIG_ID,
  PATH_INFO,
  PATH_LOGIN,
  PATH_TIMELAPSE,
  PATH_TIMELAPSE_ID,
  PATH_TREECHART,
  PATH_NEWDATABYCSV,
  PATH_RIEGO,
};

const LISTAS_DATA = [
  {
    path: PATH_ADMIN,
    ComponentIcon: FormatListNumbered,
    text: "Tareas",
  },
  {
    path: PATH_LINK,
    ComponentIcon: VpnKey,
    text: "Token",
  },
  {
    path: PATH_CALENDAR,
    ComponentIcon: InsertInvitation,
    text: "Calendario",
  },
  {
    path: PATH_IO_LIST,
    ComponentIcon: Power,
    text: "Entradas/Salidas",
  },
  {
    path: PATH_TREECHART,
    ComponentIcon: AccountTree,
    text: "Sistema",
  },
  /*{
    path: PATH_BINNACLE,
    ComponentIcon: ImportContacts,
    text: "Bitácora",
  },*/
  {
    path: PATH_GRAPH,
    ComponentIcon: Timeline,
    text: "Gráficas",
  },
  {
    path: PATH_RIEGO,
    ComponentIcon: Waves,
    text: "Rutina de Riego",
  },
  /*{
        path:PATH_NEWDATABYCSV,
        ComponentIcon:PublishIcon,
        text:'Subir Lecturas',
    },*/
];

const RUTA_PRIVATE_DATA = [
  { path: PATH_GRAPH, ComponentReact: Graph },
  { path: PATH_ADMIN, ComponentReact: Tareas },
  { path: PATH_TREECHART, ComponentReact: TreeChart },
  { path: PATH_DASHBOARD, ComponentReact: DashboardWCtx },
  { path: PATH_CONFIG_ID, ComponentReact: ConfigIO },
  { path: PATH_LINK, ComponentReact: Token },
  { path: PATH_INFO, ComponentReact: InfoUser },
  { path: PATH_IO_LIST, ComponentReact: IOList },
  { path: PATH_BINNACLE, ComponentReact: Manual },
  { path: PATH_CALENDAR, ComponentReact: Calendar },
  { path: PATH_TIMELAPSE_ID, ComponentReact: Timelapse },
  { path: PATH_INDEX, ComponentReact: Crop },
  { path: PATH_NEWDATABYCSV, ComponentReact: NewDataByCSV },
  { path: PATH_RIEGO, ComponentReact: IrrigationForm },
];

export { LISTAS_DATA, RUTA_PRIVATE_DATA, ALL_PATHS };
