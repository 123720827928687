import React from 'react'
import {db} from '../../../config/firebase'
import moment from 'moment'
import 'moment/locale/es'

import {UserContext} from '../../../context/UserProvider'

const Tareas = (props) => {

    

    const [tareas,setTareas]=React.useState([])
    const [tarea,setTarea]=React.useState('')
    const [modoEdicion,setModoEdicion]=React.useState(false)
    const [id,setId]=React.useState('')
    const [ultimo,setUltimo]=React.useState(null)
    const [desactivar,setDesactivar]=React.useState(false)
    const {usuario}=React.useContext(UserContext)
    
    React.useEffect(()=>{
      const obtenerDatos=async()=>{
        try{
          
          setDesactivar(true)
          const data=await db.collection(usuario.username+'/Keeps/Tareas')
          .limit(3)
          .orderBy('fecha',"desc")
          .get()
          //console.log("obj")
          const arraydata=data.docs.map(doc=>( { id: doc.id,...doc.data()} ))
          console.log(arraydata)
          setUltimo(data.docs[data.docs.length-1]);
          setTareas(arraydata);
          
          const query=await db.collection(usuario.username+'/Keeps/Tareas')
          .limit(3)
          .orderBy('fecha',"desc")
          .startAfter(data.docs[data.docs.length-1])
          .get();
          
          if(query.empty){
            console.log('no hay documentos')
            setDesactivar(true)
          }else{
            
            setDesactivar(false)
          }
          console.log("obj")

        }catch(error){
          console.log(error)
        }
      }
      obtenerDatos()
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const siguiente=async()=>{
      try {
        const add=usuario.username+'/Keeps/Tareas';
        const data=await db.collection(add)
          .limit(3)
          .orderBy('fecha',"desc")
          .startAfter(ultimo)
          .get()

          const arraydata=data.docs.map(doc=>( { id: doc.id,...doc.data()} ))
          setTareas([
            ...tareas,
            ...arraydata
          ])
          setUltimo(data.docs[data.docs.length-1])

          const query=await db.collection(add)
          .limit(3)
          .orderBy('fecha',"desc")
          .startAfter(data.docs[data.docs.length-1])
          .get()
          if(query.empty){
            console.log('no hay documentos')
            setDesactivar(true)
          }else{
            setDesactivar(false)
          }
          

      } catch (error) {
        console.log(error)
      }
    }

    const agregar=async(e)=>{
      e.preventDefault()
      if(!tarea.trim()){
        console.log('essta vacio')
        return
      }
      try {
        
        const nuevaTarea={
          name:tarea,
          fecha:Date.now()
        }
        const add=usuario.username+'/Keeps/Tareas';
        console.log(add)
        const data=await db.collection(add).add(nuevaTarea)
        setTareas([
          ...tareas,
          {...nuevaTarea,id:data.id}
        ])
        setTarea('')
      } catch (error) {
        console.log(error)
      }
    }
    const eliminar=async(id)=>{
      try {
        const add=usuario.username+'/Keeps/Tareas';
        await db.collection(add).doc(id).delete()
        const arrayFiltrado=tareas.filter(item=> item.id !== id)
        setTareas(arrayFiltrado)
  
      } catch (error) {
        console.log(error)
      }
    }
    const activarEdicion=(item)=>{
      
      setModoEdicion(true)
      setTarea(item.name)
      setId(item.id)
    }
    const editar=async(e)=>{
      e.preventDefault()
      if(!tarea.trim()){
        console.log('vacio')
        return
      }
      try {
        const add=usuario.username+'/Keeps/Tareas';
        await db.collection(add).doc(id).update(
          {name:tarea}
        )
        const arrayEditado=tareas.map(item=>(
          item.id=== id ? {id: item.id, fecha:item.fecha,name:tarea}:item
        ))
        setTareas(arrayEditado)
        setModoEdicion(false)
        setTarea('')
        setId('')
      } catch (error) {
        console.log(error)
      }
    }
    return (
      <div className="container mt-3">
  
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group">
              {
                tareas.map(item=>(
                  <li key={item.id} className="list-group-item">
                    {item.name}-{moment(item.fecha).format('LLL')}
                    <button 
                    className="btn btn-danger btn-sm float-right"
                    onClick={()=>eliminar(item.id)}
                    >
                      Eliminar
                    </button>
                    <button 
                    className="btn btn-warning btn-sm float-right mr-2"
                    onClick={()=>activarEdicion(item)}
                    >
                      Editar
                    </button>
                  </li>
                ))
              }
            </ul>
            <button 
            className="btn btn-info btn-block mt-2 btn-sm"
            onClick={()=>siguiente()}
            disabled={desactivar}
            >
              Siguiente..
            </button>
          </div>
          <div className="col-md-6">
            <h3>
              {
              modoEdicion? 'Editar Tarea':'Agregar Tarea'
              }
            </h3>
            <form onSubmit={modoEdicion? editar:agregar}>
              <input
              type="text"
              placeholder="Ingrese Tarea"
              className="form-control mb-2"
              onChange={e=> setTarea(e.target.value)}
              value={tarea}
              >
              </input>
              <button 
                className={
                  modoEdicion? "btn btn-warning btn-block": "btn btn-dark btn-block"
                }
                type="submit"
                >
                  {
                    modoEdicion? 'Editar':'Agregar'
                  }
                </button>
            </form>
          </div>
        </div>
  
        
      </div>
    );
}

export default Tareas
export {Tareas}
