import svgInImg from "../assets/in.png";

import HumedadImg from "../assets/humedad.png";
import TempImg from "../assets/temp.png";
import SoilImg from "../assets/soil.png";
import SwitchImg from "../assets/switch.png";
import svgOutImg from "../assets/out.png";
import ClockImg from "../assets/clock.png";

import PhSensorImg from "../assets/phSensor.png";
import CeSensorImg from "../assets/ceSensor.png";
import PumbImg from "../assets/pumb.png";
import WTempImg from "../assets/WTemp.png";

import svgNodeImg from "../assets/node.png";
import AmbienteImg from "../assets/Ambiente.png";
import AguaImg from "../assets/Agua.png";

const backGroundList = "#616161";

const IN_HUMEDAD = "0";
const IN_TEMPERATURA = "1";
const IN_SUSTRATO = "2";
const IN_SWITCH = "3";
const OUT_AC = "5";
const IN_RELOJ = "D";
const OUT_PUMB_DC = "6";
const IN_PH = "7";
const IN_CE = "8";
const IN_TEMP_AGUA = "9";
const IN_TEMP_HOJA = "10";
const OUT_PWM = "11";
const IN_PAR = "12";
const IN_DPV = "13";
const IN_DLI = "14";
const IN_CO2 = "15"; //Co2
const IN_ORP_OD = "16";
//16 ORP/Oxigeno disuelto

const TYPE_MODULE_AMBIENTE = "1";
const TYPE_MODULE_AGUA = "2";

const MODULE_TYPE_MASTER = "0";
const MODULE_TYPE_AMBIENTE = "1";
const MODULE_TYPE_AGUA = "2";

const VIDEO_ITEM = "video";
const REMINDER_ITEM = "reminder";

const MILLI_SECONDS = 1000;
export const TIME_TO_ASK_ESP32 = 60 * MILLI_SECONDS;

const ALL_KINDS = {
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_SWITCH,
  OUT_AC,
  IN_RELOJ,
  OUT_PUMB_DC,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  IN_TEMP_HOJA,
  OUT_PWM,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
  IN_ORP_OD
};

export const continuosSensors = [
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  IN_TEMP_HOJA,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
  IN_ORP_OD
];

export const discreteSensors = [IN_SWITCH];
export const discreteOuts = [OUT_AC, OUT_PUMB_DC];
export const TYPES_OF_DEVICES = {
  MODULE_TYPE_MASTER,
  MODULE_TYPE_AMBIENTE,
  MODULE_TYPE_AGUA,
};
export const TOGGLE_POS_AUTO = "2";

const svgKindIO = (kind) => {
  let svg = {
    shape: "image",
    href: svgInImg,
    type: "Entrada",
    unit: "",
  };

  if (kind === IN_HUMEDAD) {
    svg.href = HumedadImg;
    svg.unit = "%";
    svg.type = "S. de Humedad";
  } else if (kind === IN_TEMPERATURA) {
    svg.href = TempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura";
  } else if (kind === IN_SUSTRATO) {
    svg.href = SoilImg;
    svg.unit = "%";
    svg.type = "S. de Humedad Tierra";
  } else if (kind === IN_SWITCH) {
    svg.href = SwitchImg;
    svg.unit = "";
    svg.type = "S. de contacto seco";
  } else if (kind === OUT_AC) {
    svg.href = svgOutImg;
    svg.unit = "";
    svg.type = "Salida C.A";
  } else if (kind === IN_RELOJ) {
    svg.href = ClockImg;
    svg.unit = "";
    svg.type = "Reloj";
  } else if (kind === OUT_PUMB_DC) {
    svg.href = PumbImg;
    svg.unit = "";
    svg.type = "Bomba Dosificadora";
  } else if (kind === IN_PH) {
    svg.href = PhSensorImg;
    svg.unit = "";
    svg.type = "S. de PH";
  } else if (kind === IN_CE) {
    svg.href = CeSensorImg;
    svg.unit = "mS/cm";
    svg.type = "S. de CE";
  } else if (kind === IN_TEMP_AGUA) {
    svg.href = WTempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura de Agua";
  } else if (kind === IN_TEMP_HOJA) {
    svg.href = TempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura de la Hoja";
  } else if (kind === OUT_PWM) {
    svg.href = svgOutImg;
    svg.unit = "%";
    svg.type = "Salida DC variable";
  } else if (kind === IN_PAR) {
    svg.href = PhSensorImg;
    svg.unit = "µmol/m²s";
    svg.type = "S. PAR (Luminosidad)";
  } else if (kind === IN_DPV) {
    svg.href = PhSensorImg;
    svg.unit = "KPa";
    svg.type = "S. DPV";
  } else if (kind === IN_DLI) {
    svg.href = PhSensorImg;
    svg.unit = "u";
    svg.type = "S. DLI";
  }

  return svg;
};

const svgTypeModule = (type) => {
  let svg = {
    shape: "image",
    href: svgNodeImg,
    type: "Modulo",
  };

  if (type === TYPE_MODULE_AMBIENTE) {
    svg.href = AmbienteImg;
    svg.type = "Módulo de ambiente";
  } else if (type === TYPE_MODULE_AGUA) {
    svg.href = AguaImg;
    svg.type = "Módulo de agua";
  }

  return svg;
};

/** Cambiar el lenguaje de las opciones de la gráfica*/
const HIGHCHART_OPT_SPANISH = {
  lang: {
    loading: "Cargando...",
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    weekdays: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    shortMonths: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    exportButtonTitle: "Exportar",
    printButtonTitle: "Importar",
    rangeSelectorFrom: "Desde",
    rangeSelectorTo: "Hasta",
    rangeSelectorZoom: "Período",
    downloadPNG: "Descargar imagen PNG",
    downloadJPEG: "Descargar imagen JPEG",
    downloadPDF: "Descargar imagen PDF",
    downloadSVG: "Descargar imagen SVG",
    printChart: "Imprimir",
    resetZoom: "Reiniciar zoom",
    resetZoomTitle: "Reiniciar zoom",
    thousandsSep: ",",
    decimalPoint: ".",
  },
};

export {
  backGroundList,
  svgKindIO,
  svgTypeModule,
  HIGHCHART_OPT_SPANISH,
  VIDEO_ITEM,
  REMINDER_ITEM,
  ALL_KINDS,
  TYPE_MODULE_AMBIENTE,
  TYPE_MODULE_AGUA,
};
